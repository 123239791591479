<template>
  <validation-observer
    ref="form"
    tag="div"
  >
    <div class="item-general-form">
      <div>
        <b-row class="align-items-center">
          <b-col
            md="6"
            class="mb-1"
          >
            <ok-text-input
              v-model="item.name"
              size="md"
              :label="`${$t('store.item-name')} (${$t(`locales.${selectedLocale}`)})`"
              is-required
            />
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <ok-text-input
              v-model="item.price"
              size="md"
              :label="$t('store.prices.price')"
            />
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-form-checkbox
              v-model="item.status"
              :checked="item.status===1"
              :value="1"
              :unchecked-value="0"
              switch
            >
              {{ $t('general.status') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormCheckbox } from 'bootstrap-vue'
import pick from 'lodash/pick'
import { ValidationObserver } from 'vee-validate'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import LocalizationService from '@/services/localization-service'
import EditItemMixin from '../mixins/edit-modifier-mixin'

export default {
  name: 'ModifierGeneralForm',
  components: {
    BFormCheckbox,
    OkTextInput,
    ValidationObserver,
    BRow,
    BCol,
  },
  mixins: [EditItemMixin],
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      item: {},
      modified: false,
      modifiedReady: true,
      category: {},
      subcategory: {},
    }
  },
  computed: {
    editMode() {
      return !!this.$route.params?.id
    },
  },
  watch: {
    active(active) {
      if (active) {
        this.init()
      }
    },
  },
  methods: {
    setAndInit() {
      this.setLocales()
      this.init()
    },
    init() {
      this.item.name = LocalizationService.getTranslate(this.item, 'name', this.selectedLocale, true)
      if (!this.editMode) this.item.status = 1
    },
    setLocales() {
      this.item.locales = [{ locale: this.selectedLocale, name: this.item.name }]
    },
    prepareForSubmit() {
      this.setLocales()

      const keys = ['price', 'status', 'name']
      const values = pick(this.item, keys)
      const { locales } = this.item
      return {
        ...values,
        locales,
      }
    },
    async isValid() {
      return this.$refs.form.validate()
    },
  },
}
</script>

<style lang="scss">
.item-general-form {
  .parent-category-select {

    .vs__dropdown-toggle {
      max-height: 38px;

      .vs__selected {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: initial;
      }
    }
  }
}
</style>
