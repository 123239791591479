<template>
  <div>
    <ok-table
      ref="datatable"
      :title="$t('modifiers.group-title')"
      :config="config"
      @edit="openModal($event)"
      @delete="handleDelete"
    >
      <template slot="buttons">
        <b-button
          size="md"
          variant="dark"
          class="mx-md-1"
          @click="openModal"
        >
          {{ $t('modifiers.add-group') }}
        </b-button>
      </template>
      <template
        slot="title-column"
        slot-scope="{props}"
      >
        <b-badge
          variant="primary"
        >
          {{ getTitle(props.row) }}
        </b-badge>

      </template>
      <template
        v-slot:status-column="{props}"
      >
        <b-form-checkbox
          switch
          :unchecked-value="0"
          :checked="props.row.status"
          :value="1"
          @input="updateStatus($event,'status',props.row.id)"
        />

      </template>
    </ok-table>
    <process-group-modal
      v-model="showGroupModal"
      :group="selectedGroup"
      @updated="refreshTable"
    />
  </div>
</template>

<script>
import { BButton, BBadge, BFormCheckbox } from 'bootstrap-vue'
import OkTable from '@/@bya3/components/tables/ok-table/OkTable'
import LocalizationService from '@/services/localization-service'
import ModifierAPIs from '@/api/store/modifier'
import ProcessGroupModal from '@/views/store/modifiers/ProcessGroupModal'
import GroupsTableConfig from './config/groups-table-config'

export default {
  components: {
    ProcessGroupModal,
    OkTable,
    BButton,
    BBadge,
    BFormCheckbox,
  },
  data() {
    return {
      config: GroupsTableConfig,
      showGroupModal: false,
      selectedGroup: {},
    }
  },
  async mounted() {
    await this.fetchModifiers()
  },
  methods: {
    getTitle(item) {
      return LocalizationService.getTranslate(item, 'name', this.selectedItem)
    },
    refreshTable() {
      this.showGroupModal = false
      this.selectedGroup = {}
      this.$refs.datatable.loadItems()
    },
    openModal(data = null) {
      this.showGroupModal = true
      this.selectedGroup = data?.row ?? null
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => ModifierAPIs.deleteGroup(data.row.id)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
    updateStatus(value, column, id) {
      const data = { id }
      data[column] = value
      ModifierAPIs.processGroup(data)
        .then(response => {
          this.$notifySuccess(response.message)
          this.refreshTable()
        })
        .catch(e => this.$notifyError(e))
    },
    async fetchModifiers() {
      await this.$store.dispatch('modifier/FETCH_MODIFIERS').catch(e => { console.log(e) })
    },
  },
}
</script>
