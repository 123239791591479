<template>
  <div>
    <ok-table
      ref="datatable"
      :title="$t('modifiers.modifier-title')"
      :config="config"
      @edit="openModal($event)"
      @delete="handleDelete"
    >
      <template #buttons>
        <b-button
          size="md"
          variant="dark"
          class="mx-md-1"
          @click="openModal"
        >
          {{ $t('modifiers.add-modifier') }}
        </b-button>
        <b-button
          size="md"
          variant="outline-warning"
          @click="showExcel=true"
        >
          {{ $t('excel.import') }}
        </b-button>
      </template>
      <template
        v-slot:title-column="{props}"
      >
        <b-badge
          variant="primary"
        >
          {{ getTitle(props.row) }}
        </b-badge>

      </template>
      <template
        v-slot:status-column="{props}"
      >
        <b-form-checkbox
          switch
          :unchecked-value="0"
          :checked="props.row.status"
          :value="1"
          @input="updateStatus($event,'status',props.row.id)"
        />

      </template>
    </ok-table>
    <process-modifier-modal
      v-model="showModModal"
      :modifier="selectedMod"
      @updated="refreshTable"
    />
    <excel-import-modal
      v-model="showExcel"
      type="modifiers"
    />
  </div>
</template>

<script>
import { BButton, BBadge, BFormCheckbox } from 'bootstrap-vue'
import OkTable from '@/@bya3/components/tables/ok-table/OkTable'
import LocalizationService from '@/services/localization-service'
import ModifierAPIs from '@/api/store/modifier'
import ProcessModifierModal from '@/views/store/modifiers/ProcessModifierModal'
import ExcelImportModal from '@/views/store/ExcelImportModal'
import ModifiersTableConfig from './config/modifiers-table-config'

export default {
  components: {
    ExcelImportModal,
    ProcessModifierModal,
    OkTable,
    BButton,
    BBadge,
    BFormCheckbox,
  },
  data() {
    return {
      config: ModifiersTableConfig,
      showModModal: false,
      selectedMod: {},
      showExcel: false,
    }
  },
  methods: {
    getTitle(item) {
      return LocalizationService.getTranslate(item, 'name', this.selectedItem)
    },
    openModal(data = null) {
      this.showModModal = true
      this.selectedMod = data?.row ?? null
    },
    refreshTable() {
      this.showModModal = false
      this.selectedMod = {}
      this.$refs.datatable.loadItems()
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => ModifierAPIs.delete(data.row.id)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
    updateStatus(value, column, id) {
      const data = { id }
      data[column] = value
      ModifierAPIs.process(data)
        .then(response => {
          this.$notifySuccess(response.message)
          this.refreshTable()
        })
        .catch(e => this.$notifyError(e))
    },
  },
}
</script>
